import './App.css';
import Ball from './components/Ball/Ball';
import ballImage from './img/balon.png';
import CardPayment from './components/CardPayment/CardPayment';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*  <CardPayment /> */}
        <Ball imageUrl={ballImage} speed={24} />{/* El balón girará con una velocidad de 24 segundos por vuelta */}
        <p>
          <code>Coming soon...</code>
        </p>
      </header>
    </div>
  );
}

export default App;
