// Ball.jsx
import React from 'react';
import './Ball.css';

const Ball = ({ imageUrl, speed }) => {
  return (
    <div className="rotating-image-container" style={{ animationDuration: `${speed}s` }}>
      <img src={imageUrl} alt="Rotating" className="rotating-image" />
    </div>
  );
};

export default Ball;
